<template>
  <v-container
    class="container-full-size"
  >
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :no-data-text="str['documents_empty']"
      :no-results-text="str['documents_empty']"
      class="elevation-1"
      @click:row="openEditItem"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="str['search']"
            single-line
            hide-details
          />
          <v-spacer />
          <v-dialog
            v-model="dialogItem"
            persistent
            max-width="750px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="openNewItem()"
              >
                {{ str['add'] }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span
                  v-if="!itemToEdit"
                  class="headline"
                >
                  {{ str['new_document'] }}
                </span>
                <span
                  v-if="itemToEdit"
                  class="headline"
                >
                  {{ str['edit_document'] }}
                </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      v-for="item in data"
                      :key="item.id"
                      cols="12"
                    >
                      <v-text-field
                        v-if="item.type === 'input'"
                        v-model="item.value"
                        :label="item.title + (item.required ? ' *' : '')"
                        :disabled="item.disabled ? true : false"
                        hide-details
                      />
                      <v-textarea
                        v-if="item.type === 'textarea'"
                        v-model="item.value"
                        :label="item.title + (item.required ? ' *' : '')"
                        :disabled="item.disabled ? true : false"
                        hide-details
                      />
                      <div v-if="item.type === 'image'">
                        <div>
                          {{ str['image'] }}{{ item.required ? ' *' : '' }}
                        </div>
                        <div
                          v-if="item.value"
                          class="form-field-image"
                        >
                          <input
                            id="field-input-image"
                            type="file"
                            @change="addNewImage"
                            accept=".png,.jpg,.jpeg"
                          >
                          <v-img
                            contain
                            :src="item.value"
                            alt="Conteúdo"
                            width="300px"
                            height="300px"
                          />
                        </div>
                        <div
                          v-if="!item.value"
                          class="form-field-image"
                        >
                          <input
                            id="field-input-image"
                            type="file"
                            @change="addNewImage"
                            accept=".png,.jpg,.jpeg"
                          >
                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            small
                            color="success"
                          >
                            <v-icon dark>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="default"
                  @click="closeItem"
                >
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn
                  color="success"
                  @click="saveItem"
                >
                  {{ str['save'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.image="{ item }">
        <div
          class="p-2 text-center"
          style="display: flex; justify-content: center; padding: 10px 0;"
        >
          <v-img
            contain
            :src="item.image"
            :alt="item.name"
            width="60px"
            height="60px"
          />
        </div>
      </template>
      <template v-slot:item.description="{ item }">
        <div class="ellipsis-rows-1">
          {{ item.description }}
        </div>
      </template>
      <template v-slot:item.url="{ item }">
        <div
          class="ellipsis-rows-1"
          style="width:300px;"
        >
          <a
            target="_blank"
            :href="item.url"
            @click.stop
          >
            {{ item.url }}
          </a>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-0"
          color="error"
          @click.stop="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'

  export default {
    name: 'client-documents',
    props: ['parent', 'client'],
    data() {
      const user = Utils.getUser()
      const config = user && user.configurations ? user.configurations : null
      const headers = [
        { text: window.strings['name'], value: 'name', align: 'left', width: 200 },
        { text: window.strings['url'], value: 'url', align: 'left', width: 200 },
        { text: window.strings['actions'], value: 'actions', sortable: false, align: 'center', width: 70 },
      ]
      const form = [
        {
          type: 'input',
          id: 'name',
          title: window.strings['name'],
          value: '',
          required: true,
        },
        {
          type: 'textarea',
          id: 'description',
          title: window.strings['description'],
          value: '',
          required: false,
        },
        {
          type: 'input',
          id: 'url',
          title: window.strings['url'],
          value: '',
          required: false,
        },
        {
          type: 'image',
          id: 'image',
          title: window.strings['image'],
          value: '',
          required: false,
        },
      ]

      return {
        str: window.strings,
        user: user,
        config: config,
        headers: headers,
        data: form,
        imageMaxSize: 600,
        items: [],
        search: '',
        dialogItem: false,
        itemToEdit: null,
      }
    },
    watch: {
      dialogItem(val) {
        const dialog = document.getElementsByClassName('v-dialog')
        if (dialog && dialog[0]) {
          dialog[0].scrollTo(0, 0)
        }
        val || this.closeItem()
      },
    },
    beforeMount: function () {
      this.main()
    },
    methods: {
      main() {
        if (!this.user) {
          return false
        }
        this.getItems()
      },
      getItems: function () {
        const self = this
        this.$isLoading(true)
        Api.getContents({
          client_id: this.client.dbId,
          photo: true,
          fields: ['id', 'name', 'url']
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.items = response.data
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      openNewItem() {
        this.setData()
        this.itemToEdit = null
        this.dialogItem = true
      },
      openEditItem(item) {
        const self = this
        this.$isLoading(true)
        Api.getContents({
          client_id: this.client.dbId,
          id: item.id,
          photo: true
        }, function (response) {
          self.$isLoading(false)
          if (response.success && response.data && response.data[0]) {
            const src = response.data[0]
            self.setData(src)
            self.itemToEdit = src
            self.dialogItem = true
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      setData: function (item) {
        for (let i = 0; i < this.data.length; i++) {
          if (item) {
            this.data[i].value = item[this.data[i].id]
          } else {
            this.data[i].value = ''
          }
        }
      },
      closeItem() {
        this.itemToEdit = null
        this.dialogItem = false
      },
      saveItem() {
        const self = this
        const data = this.getData()

        if (data) {
          this.$isLoading(true)

          if (data.id) {
            Api.updateContent(data, function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.closeItem()
                for (let i = 0; i < self.items.length; i++) {
                  if (self.items[i].id === data.id) {
                    self.$set(self.items, i, response.data)
                    break
                  }
                }
              } else {
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          } else {
            Api.newContent(data, function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.closeItem()
                self.items.push(response.data)
              } else {
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          }
        }
      },
      getData: function () {
        const data = {
          client_id: this.client.dbId,
        }
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].required && !this.data[i].value) {
            return this.$alert(
              window.strings['fields_to_be_filled'],
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
          data[this.data[i].id] = this.data[i].value
        }
        if (this.itemToEdit) {
          data.id = this.itemToEdit.id
        }
        return data
      },
      deleteItem(item) {
        const self = this
        const itemId = item.id

        this.$confirm(
          window.strings['want_delete'] + (item ? (' "' + item.name + '"') : '') + '?',
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.$isLoading(true)
          Api.deleteContent({
            client_id: self.client.dbId,
            id: itemId
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              for (let i = 0; i < self.items.length; i++) {
                if (self.items[i].id === itemId) {
                  self.items.splice(i, 1)
                  break
                }
              }
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => { })
      },
      addNewImage(event) {
        const self = this
        const file = event.srcElement.files[0]
        const reader = new FileReader()

        reader.readAsDataURL(file)
        reader.onload = function (e) {
          const image = new Image()
          image.src = reader.result

          image.onload = function () {
            if (image.width < self.imageMaxSize && image.height < self.imageMaxSize) {
              self.saveNewImage(e.target.result)
            } else {
              self.saveNewImage(resizeImage(image))
            }
          }
        }
        reader.onerror = function () { }

        function resizeImage(image) {
          const canvas = document.createElement('canvas')
          let width = image.width
          let height = image.height

          if (width > height) {
            if (width > self.imageMaxSize) {
              height *= self.imageMaxSize / width
              width = self.imageMaxSize
            }
          } else {
            if (height > self.imageMaxSize) {
              width *= self.imageMaxSize / height
              height = self.imageMaxSize
            }
          }
          canvas.width = width
          canvas.height = height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, 0, width, height)
          return canvas.toDataURL()
        }
      },
      saveNewImage(image) {
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].id === 'image') {
            this.data[i].value = image
            this.$set(this.data, i, this.data[i])
            break
          }
        }
        document.getElementById('file-input-image').value = ''
      },
    },
  }
</script>
